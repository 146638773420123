import React from "react"
import Layout from "../components/Layout/layout"
import { Helmet } from "react-helmet"

import FirstSection from "./landing/sections/FirstSection"
import SecondSection from "./landing/sections/SecondSection"
import ThirdSection from "./landing/sections/ThirdSection"
import FourthSection from "./landing/sections/FourthSection"
import FifthSection from "./landing/sections/FifthSection"
import SixthSection from './landing/sections/SixthSection';
import SeventhSection from './landing/sections/SeventhSection';
import FAQSection from "./landing/sections/FAQSection"
import ContactForm from "./landing/sections/ContactSection"
import Ecommerce from "./landing/sections/Ecommerce"

const IndexPage = () => {
    return (
        <Layout infoPage={{ page: "landing" }}>
            {/* <Helmet>
                <script src="https://www.google.com/recaptcha/api.js?render=6LcB4LcZAAAAAPbot6BEB2Cv0Ygwf6Y5exJY8dDC"></script>
            </Helmet> */}
            <main className="landing">
                <FirstSection />
                <SecondSection />
                <ThirdSection />
                <FourthSection />
                <FifthSection />
                <SixthSection />
                <SeventhSection />
                <FAQSection />
                <Ecommerce />
                <ContactForm />
            </main>
        </Layout>
    )
}
export default IndexPage
