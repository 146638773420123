import React from "react"
import bubbleIMG from "../../../components/assets/img/pages/landing/new/bubble.png"
import giftIMG from "../../../components/assets/img/pages/landing/new/gift.png"

const FourthSection = () => {
    return (
        <section className="fourth">
            <div className="row">
                <div className="img-box">
                    <img src={bubbleIMG} alt="chat-bubble" />
                    <span className="white-font">
                        Por que usar
                        <br />o <span className="font-bold">Gifthy?</span>
                    </span>
                    <img src={giftIMG} alt="gift-box" />
                </div>
                <div className="text-box">
                    <h1 className="font-bold">
                        O Gifhty surpreende você e quem você deseja presentear.
                    </h1>
                    <p>
                        Além de notificar datas importantes, também apresenta um
                        catálogo completo com sugestões de produtos, conforme o
                        perfil do presenteado, para não ter erro na hora da
                        escolha. Você poupa tempo e evita incômodos, como
                        trânsito e shoppings abarrotados.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default FourthSection
