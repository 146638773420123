import React from "react"

const SixthSection = () => {
    return (
        <section className="sixth">
            <div className="left">
                <div className="item">
                    <span className="title yellow-font">
                        Como funciona{" "}
                        <span className="blue-font font-bold">o Gifthy</span>
                    </span>
                    <p className="description blue-font">
                        Você cria um perfil e escolhe os presentes que gostaria
                        de receber. Convida seus amigos, que farão o mesmo.
                    </p>
                </div>
                <div className="item">
                    <span className="title yellow-font">
                        Pronto!
                        <br />
                        <span className="blue-font font-bold">
                            Está formada a rede.
                        </span>
                    </span>
                    <p className="description blue-font">
                        Quando chegar, por exemplo, o aniversário de um
                        participante, seus amigos serão lembrados com
                        antecedência e saberão quais presentes poderão comprar.
                        Se o participante não escolher, o Gifthy indicará, com
                        base em seu estilo de vida. Sem risco de errar! Além
                        disso, vários amigos podem dividir a compra de um ítem
                        de maior valor, possibilitando ofertar um presente
                        inesquecível
                    </p>
                </div>
            </div>
        </section>
    )
}

export default SixthSection
